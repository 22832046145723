import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Button from '@material-ui/core/Button';
import "../scss/pages/signin.scss";
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { renderFileField, renderInputField, renderNumberField, renderSelectField } from '../components/common/FormField';
// import { country } from '../utils/countries';
import { createAccNext as validate } from '../validate/validate';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getCountry, signUp } from '../Redux/login/Action';
import swal from 'sweetalert';
import { removeNumberFormating, toAbsoluteUrl, uniqueidGenerator } from '../utils';
import LoaderBox from '../components/common/Loader';

const TITLE = "LMM :: Create Account Next"

let CreateAccNext = ({ handleSubmit, onChange, countryValue }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const isLoginState = useSelector(state => state.login.isLoggedIn)
  const step1 = useSelector(state => state.form.createAcc)
  const country = useSelector(state => state.login.country)

  const [ImagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController()
    dispatch(getCountry())

    return () => {
      abortController.abort();
    }
  }, [dispatch])

  const handleImageChange = delegate => e => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    reader.readAsDataURL(e.target.files.item(0))
  }

  const onFormSubmit = (data) => {

    let newData = { ...data, ...step1.values, contactNumber: removeNumberFormating(data.contactNumber), deviceType: 'web', deviceId: uniqueidGenerator() }
    setIsLoading(true)
    dispatch(signUp(newData))
      .then(res => {
        setIsLoading(false)

        if (res.status === 200) {
          // swal("", res.message, "success")
          history.push('/')
          dispatch(reset('createAcc'))
        } else {
          swal("", res.message, "error")
        }
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response.data.status === 400) {
          swal("", err.response.data.message, "error")
        }
        else {
          swal("", 'Please try again', "error")
        }
      })
  }

  if (isLoginState)
    return <Redirect to="/" />

  else if (!step1) {
    return <Redirect to="/create-account" />
  }

  else
    return (
      <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <section className="accountsec accsecnext">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 login-box create-account">
                  <div className="login-content d-flex align-items-center justify-content-center flex-column">
                    <div className="acc-header-top d-flex justify-content-between">
                      <Link to='/create-account'><img src={toAbsoluteUrl("/images/np_arrow_white_back.svg")} alt="" /></Link>
                      <div className="acc-help">
                        <img src={toAbsoluteUrl("/images/np_question.svg")} alt="" />
                        <a href="mailto:support@legacymindedmen.org">Help</a>
                      </div>
                    </div>
                    <div className="acc-header">
                      <h3 className="logintitle">Create Account</h3>
                    </div>
                    <div className="login-box-inner acc-img-upload d-flex align-items-center justify-content-center">
                      <div className="login-box-inner">
                        <div className="uploadimg-box" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/plus.svg')})` }}>
                          <Field
                            name="photo"
                            type="file"
                            component={renderFileField}
                            picture={{ src: ImagePreview && ImagePreview, alt: "Resource" }}
                            onChange={handleImageChange(onChange)} />
                        </div>
                      </div>
                      <ul>
                        <Field name="firstname"
                          type="text"
                          component={renderInputField}
                          placeholder="First Name"
                          required />
                        <Field name="lastname"
                          type="text"
                          component={renderInputField}
                          placeholder="Last Name"
                          required />
                        <Field name="contactNumber"
                          type="text"
                          component={renderNumberField}
                          placeholder="Contact Number"
                          required />
                        <Field name="country"
                          component={renderSelectField}
                          placeholder="Country"
                          options={country}
                        />
                        {countryValue === 'USA' && <Field name="state"
                          type="text"
                          component={renderSelectField}
                          placeholder="State (Optional)"
                          options={country.length > 0 && country[0].states}
                          required />}
                      </ul>
                    </div>
                    <div className="acc-footer">
                      {isLoading ? <LoaderBox /> : <Button className="btn" disabled={isLoading} type="submit">Create My Free Account</Button>}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 create-account-img login-box-content d-flex align-items-start justify-content-end" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/engage-img.png')})` }}>
                  <div className="login-content-inner">
                    <h3>ENGAGE</h3>
                    <h3>EQUIP</h3>
                    <h3>ENCOURAGE</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </>
    )
}
const selector = formValueSelector('createAccNext') // <-- same as form name

CreateAccNext = connect(state => {
  // can select values individually
  const countryValue = selector(state, 'country')

  return {
    countryValue
  }
})(CreateAccNext)

export default reduxForm({
  form: 'createAccNext',
  validate
})(CreateAccNext);