import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'

// To signin
export const signIn = user => dispatch => {
    return axios
        .post(`${API_BASE}auth/login`, user)
        .then((res) => {
            // set access token for rest of the api
            if (res.data.status === 200) {
                axios.defaults.headers.common['authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
            }
            else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
            }
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

// To reAuth mob user
export const reAuthMobileuser = user => dispatch => {
    return axios
        .get(`${API_BASE}auth/getUserProfileWeb?token=${user}`)
        .then((res) => {
            // set access token for rest of the api
            if (res.data.status === 200) {
                axios.defaults.headers.common['authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
            }
            else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
            }
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

// To Fetch Profile
export const getProfile = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/getProfile`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.FETCH_PROFILE_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.FETCH_PROFILE_ERROR, payload: err.response })
            throw err
        })
}

// To Get Country
export const getCountry = () => dispatch => {
    return axios
        .post(`${API_BASE}auth/getCountries`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.FETCH_COUNTRY_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.FETCH_COUNTRY_ERROR, payload: err.response })
            throw err
        })
}

//To logout 
export const logout = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/logout`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.LOGOUT, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGOUT_WITHOUT_API })
            throw err
        })
}

export const signUp = data => dispatch => {

    let formData = new FormData();

    if (data.country !== 'USA') {
        delete data.state
    }

    for (var key in data) {
        if (data[key] && (Array.isArray(data[key]) || typeof data[key] === "object")) {
            formData.append(key, data[key])
        }
        else if (data[key]) {
            formData.append(key, data[key])
        }
    }
    // typeof data.photo === 'object' && Object.entries(data.photo).length > 0
    if (data.photo)
        formData.append("photo", data.photo[0], data.photo[0].name)

    return axios
        .post(`${API_BASE}auth/signup`, formData)
        .then((res) => {
            if (res.data.status === 200) {
                axios.defaults.headers.common['authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
            }
            else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
            }
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.SIGNUP_ERROR, payload: err.response })
            throw err
        })
}


// Forgot Password
export const forgotPassword = data => dispatch => {
    return axios
        .post(`${API_BASE}auth/forgotPassword`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To Change Password
export const changePassword = (data) => dispatch => {
    return axios
        .post(`${API_BASE}webapi/changePassword`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// To get tax
export const fetchTax = (data) => dispatch => {
    return axios
        .get(`${API_BASE}webapi/getTaxDetails`)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

export const editProfile = data => dispatch => {

    let formData = new FormData();

    if (data.country !== 'USA') {
        delete data.state
    }

    for (var key in data) {
        if (data[key] && (Array.isArray(data[key]) || typeof data[key] === "object")) {
            formData.append(key, data[key])
        }
        else if (data[key]) {
            formData.append(key, data[key])
        }
    }
    if (typeof data.photo === 'object' && Object.entries(data.photo).length > 0) {
        formData.append("photo", data.photo[0], data.photo[0].name)
    }
    else {
        formData.delete('photo')
    }


    return axios
        .post(`${API_BASE}webapi/editProfile`, formData)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}
