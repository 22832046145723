import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { loginReducer } from './login/Reducer'
import { homeReducer } from './home/Reducer';
import { resourceReducer } from './resourse/Reducer';
import { premiumResourceReducer } from './premium/Reducer';

export const rootReducer = combineReducers({
  login: loginReducer,
  home: homeReducer,
  resource: resourceReducer,
  premium: premiumResourceReducer,
  form: formReducer
});