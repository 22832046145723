import * as ACTION_TYPES from './Types';

const initialState = {
  isLoggedIn: false,
  userData: {},
  profile: {},
  country: [],
  message: '',
  error: null
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      localStorage.setItem('authTokenWebsite', JSON.stringify(action.payload.data.accessToken));
      localStorage.setItem('userWebsite', JSON.stringify(action.payload.data));
      return {
        ...state,
        userData: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }
    case ACTION_TYPES.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
        error: null
      }
    case ACTION_TYPES.FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        country: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data
      }
    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.LOGOUT_WITHOUT_API:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      localStorage.removeItem("authTokenWebsite");
      localStorage.removeItem("userWebsite");
      return {
        ...state,
        isLoggedIn: false,
        message: '',
        userData: {}
      }
    default:
      return state;
  }
}
