import React, { useEffect } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from "../common/Header";
// import Footer from "../common/Footer";
import routes from '../../routes';
import { ErrorBoundary } from "../../utils/ErrorBoundry";
import { getProfile } from "../../Redux/login/Action";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../Redux/premium/Action";

const Layout = props => {

  const dispatch = useDispatch();
  const userData = useSelector(state => state.login)

  useEffect(() => {
    const abortController = new AbortController()
    userData.isLoggedIn && dispatch(getProfile({ userId: userData.userData._id }))
    userData.isLoggedIn && dispatch(getCart(userData.userData._id))

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      <Header {...props} />
      <main className="app-main d-flex flex-column">
        <ErrorBoundary {...props}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <route.component {...props} isLoggedIn={userData.isLoggedIn} />
                  )}
                />
              ) : (null)
            })}
            <Redirect to="/" />
          </Switch>
        </ErrorBoundary>
      </main>
      {/* <Footer /> */}
    </>
  )
}

export default Layout;