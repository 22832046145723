//Get Premium Resourse info
export const GET_PREMIUM_RESOURSES_LIST_SUCCESS = "GET_PREMIUM_RESOURSES_LIST_SUCCESS";
export const GET_PREMIUM_RESOURSES_LIST_ERROR = "GET_PREMIUM_RESOURSES_LIST_ERROR";

//Get Card info
export const GET_CARD_INFO_SUCCESS = "GET_CARD_INFO_SUCCESS";
export const GET_CARD_INFO_ERROR = "GET_CARD_INFO_ERROR";

//Get Un purchased Resource Info
export const GET_UNPURCHASED_RESOURCE_INFO_SUCCESS = "GET_UNPURCHASED_RESOURCE_INFO_SUCCESS";
export const GET_UNPURCHASED_RESOURCE_INFO_ERROR = "GET_UNPURCHASED_RESOURCE_INFO_ERROR";

//Get purchased Resource List
export const GET_PURCHASED_RESOURCE_LIST_SUCCESS = "GET_PURCHASED_RESOURCE_LIST_SUCCESS";
export const GET_PURCHASED_RESOURCE_LIST_ERROR = "GET_PURCHASED_RESOURCE_LIST_ERROR";

//Get purchased Resource List
export const GET_PURCHASED_RESOURCE_INFO_SUCCESS = "GET_PURCHASED_RESOURCE_INFO_SUCCESS";
export const GET_PURCHASED_RESOURCE_INFO_ERROR = "GET_PURCHASED_RESOURCE_INFO_ERROR";

//Get purchased workshop List
export const GET_PREMIUM_WORKSHOP_INFO_SUCCESS = "GET_PREMIUM_WORKSHOP_INFO_SUCCESS";
export const GET_PREMIUM_WORKSHOP_INFO_ERROR = "GET_PREMIUM_WORKSHOP_INFO_ERROR";

//Get Cart List
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";




//Get Resourse info
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_ERROR = "GET_SUBCATEGORY_ERROR";

//Get Resource list for non dynamic workshop info
export const GET_NON_DYNAMIC_WORKSHOP_LIST_SUCCESS = "GET_NON_DYNAMIC_WORKSHOP_LIST_SUCCESS";
export const GET_NON_DYNAMIC_WORKSHOP_LIST_ERROR = "GET_NON_DYNAMIC_WORKSHOP_LIST_ERROR";

//Get Premium
export const GET_PREMIUM_SUCCESS = "GET_PREMIUM_SUCCESS";
export const GET_PREMIUM_ERROR = "GET_PREMIUM_ERROR";

//Get Workshop info
export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS";
export const GET_WORKSHOP_ERROR = "GET_WORKSHOP_ERROR";

