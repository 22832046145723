import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../utils'

function SearchList({ name, noData, id, searchValue, inputRef, setMobile }) {
    return (
        <li className={`legacy-search-list ${noData ? 'no-data-search' : ''}`}>
            <Link to={`/search-result?searchValue=${name}`}
                onClick={e => {
                    if (noData) { e.preventDefault() }
                    else {
                        (inputRef.current.value = name ? name : '')
                        setMobile(false)
                    }
                }}
                className="legacy-list-info">
                <div className="search-list-img">
                    {/* <img src={toAbsoluteUrl("/images/360legacy@2x.png")} alt="" /> */}
                    <span>{name}</span>
                </div>
                <div className="search-list-info">
                    <img src={toAbsoluteUrl("/images/Back_Gray.svg")} alt="" />
                </div>
            </Link>
        </li >
    )
}

export default SearchList
