import React from 'react';
import { Helmet } from 'react-helmet';
import '../scss/pages/error-boundary.scss'
// import "../scss/pages/profile.scss";

const TITLE = "LMM"

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, erroedLocation: '' };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };

    }
    componentDidUpdate(previousProps, previousState) {
        if ((previousState.currentPath !== this.props.location.pathname) && previousState.hasError) {
            this.setState({ hasError: false, currentPath: this.props.location.pathname })
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="history-section">
                    <div className="container">
                        <h1 style={{ marginBottom: '2rem' }}>Something went wrong ...</h1>

                        <a href={`${process.env.PUBLIC_URL}`} > <h3 style={{ fontWeight: 'bolder', color: 'black' }}>BACK</h3></a>
                    </div>
                </div>
            </>);
        }
        return this.props.children;
    }
}
