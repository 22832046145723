//Get Resourse info
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_ERROR = "GET_SUBCATEGORY_ERROR";

//Get Resource list for non dynamic workshop info
export const GET_NON_DYNAMIC_WORKSHOP_LIST_SUCCESS = "GET_NON_DYNAMIC_WORKSHOP_LIST_SUCCESS";
export const GET_NON_DYNAMIC_WORKSHOP_LIST_ERROR = "GET_NON_DYNAMIC_WORKSHOP_LIST_ERROR";

//Get Premium
export const GET_PREMIUM_SUCCESS = "GET_PREMIUM_SUCCESS";
export const GET_PREMIUM_ERROR = "GET_PREMIUM_ERROR";

//Get Workshop info
export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS";
export const GET_WORKSHOP_ERROR = "GET_WORKSHOP_ERROR";

