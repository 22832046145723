import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Auth(ComposedComponent) {

  const Authentication = props => {
    const isLoginState = useSelector(state => state.login.isLoggedIn)
    // const userData = useSelector(state => state.login.userData)

    const { location } = props.history;

    // let authPath = ['/cart', '/order-detail', '/order-submit', '/order-place', '/order-history', '/profile', '/additional-contact', '/additional-contact/add-additional-contact', '/additional-contact/edit-additional-contact/', '/product-group-listing', '/product-group-detail', '/best-sellers', '/new-arrivals', '/product-detail', '/change-password']
    let authPath = ['/cart', '/add-card-details', '/checkout', '/payment-method', '/change-password', '/profile-edit', '/premium-workshop', '/premium-resource-info/']

    useEffect(() => {
      authPath.map((val, i) => {
        return ((location.pathname.startsWith(val) && !isLoginState) && props.history.push('/'))
      })
      // if (!isLoginState) {
      //   props.history.push('/signin')
      // }
      // eslint-disable-next-line
    }, [isLoginState])

    return <ComposedComponent {...props} />

  }

  return withRouter((Authentication))
}
